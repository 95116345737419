import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import LoadyHorse from "../../../components/loady-horse"
import Seo from "../../../components/seo"

function HorseTimes() {
  return (
    <Layout bodyClass="archiveOrg dark-mode">
      <Seo title="Frog the Horse" />
      <div className="pageWrapper limit">
        <h1>Silent Movie: Black Cyclone</h1>
        <p>
          A film from 1925 starring Rex the Wonder Horse, Lady the Horse, and
          Kathleen Collins (Human).{" "}
          <a href="https://www.imdb.com/title/tt0015627/">From IMDB</a>: "A
          cowboy and a wild horse find they have some things in common: both
          have enemies out to get them and both must save their mates from
          danger."
        </p>
        <div className="iframeWrapper">
          <LoadyHorse />
          <iframe
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/6Z6z5hFGy6E"
            title="YouTube video player"
            frameborder="0"
            allow="picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <HorseButton />
      </div>
    </Layout>
  )
}

export default HorseTimes
